import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : { user: null, tokens: null };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userSignin: (state, action) => action.payload,
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { userSignin, updateUser } = authSlice.actions;

export default authSlice.reducer;
