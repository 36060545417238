import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../constants/baseUrl";

const initialState = {
   permissions:[]
  };

export const permissionSlice = createSlice({
    name: "permissions",
    initialState,
    reducers: {
        userPermissions: (state, action) => {
        state.permissions = action.payload
      },
    },
  });
  
  export const { userPermissions } = permissionSlice.actions;
  
  export default permissionSlice.reducer;

