import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

export const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setMaintenanceList: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setMaintenanceList } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
