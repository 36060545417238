import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import notificationsReducer from "./reducers/notificationSlice";
import permissionReducer from "./reducers/permissionSlice";
import maintenanceReducer from "./reducers/maintenanceSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    permissions: permissionReducer,
    notifications: notificationsReducer,
    maintenance: maintenanceReducer,
  },
});

export default store;
