import React, { lazy } from "react";
import "./App.css";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import { ToastContainer } from "react-toastify";

const Login = lazy(() => import("./pages/login"));
const Dashboard = lazy(() => import("./pages/dashboard/index"));
const Users = lazy(() => import("./pages/users"));
const Students = lazy(() => import("./pages/students"));
const Hhms = lazy(() => import("./pages/hhms"));
const Attendence = lazy(() => import("./pages/attendence/Attendence"));
const Drivers = lazy(() => import("./pages/drivers"));
const Mechanics = lazy(() => import("./pages/mechanics"));
const Issues = lazy(() => import("./pages/issues"));
const Modules = lazy(() => import("./pages/modules"));
const Permissions = lazy(() => import("./pages/permissions"));
const Settings = lazy(() => import("./pages/settings"));
const PrivateLayout = lazy(() => import("./layouts/PrivateLayout"));
const DriverRegisterationLayout = lazy(() => import("./layouts/DriverRegisterationLayout"));
const Profile = lazy(() => import("./pages/profile"));
const AddBus = lazy(() => import("./pages/buses/AddBus"));
const Task = lazy(() => import("./pages/task"));
const TaskGrid = lazy(() => import("./pages/taskGrid"));
const PermissionsGrid = lazy(() => import("./pages/permissionsGrid/PermissionsGrid"));
const ComponentsTypes = lazy(() => import("./pages/componentsTypes"));
const AddTask = lazy(() => import("./pages/task/AddTask"));
const AddComponent = lazy(() => import("./pages/components/AddComponent"));
const AddUser = lazy(() => import("./pages/users/AddUser"));
const AddStudent = lazy(() => import("./pages/students/AddStudent"));
const AddIssue = lazy(() => import("./pages/issues/AddIssue"));
const AddModule = lazy(() => import("./pages/modules/AddModule"));
const AddPermissions = lazy(() => import("./pages/permissions/AddPermissions"));
const AddComponentsTypes = lazy(() => import("./pages/componentsTypes/AddComponentsTypes"));
const BusProfile = lazy(() => import("./pages/busProfile"));
const UserProfile = lazy(() => import("./pages/userProfile"));
const StudentProfile = lazy(() => import("./pages/studentProfile"));

const Notfound = lazy(() => import("./pages/Notfound"));
const ComponentsIndex = lazy(() => import("./pages/components"));
const BusIndex = lazy(() => import("./pages/buses"));
const EditBus = lazy(() => import("./pages/buses/Edit"));
const AddComponents = lazy(() => import("./pages/myComponents/AddComponents"));
const EditUser = lazy(() => import("./pages/users/EditUser"));
const WorkShop = lazy(() => import("./pages/myWorkshop"));
const EditIssue = lazy(() => import("./pages/issues/EditIssue"));
const EditComponent = lazy(() => import("./pages/components/EditComponent"));
const EditTask = lazy(() => import("./pages/task/EditTask"));
const ViewPermissions = lazy(() => import("./pages/permissionsGrid/ViewPermissions"));
const EditPermission = lazy(() => import("./pages/permissionsGrid/editPermission"));
const Maintenance = lazy(() => import("./pages/maintenance"));
const MaintenanceStatus = lazy(() => import("./pages/maintenance/maintenanceStatus"));
const DVIR = lazy(() => import("./pages/maintenance/dvir"));

const VehicleProfile = lazy(() => import("./pages/maintenance/vehicleProfile"));
const Test = lazy(() => import("./pages/test"));
const AddHhm = lazy(() => import("./pages/hhms/AddHhm"));
const EditHhm = lazy(() => import("./pages/hhms/EditHhm"));
const TrackingVehicle = lazy(() => import("./pages/trackingVehicle"));
const FleetSchedule = lazy(() => import("./pages/fleetSchedule"));
const HhmProfile = lazy(() => import("./pages/hhmProfile"));
const ApplicantInfo = lazy(() => import("./pages/driverApplicationForm/applicantInfo"));
const Education = lazy(() => import("./pages/driverApplicationForm/education"));
const AddressInfo = lazy(() => import("./pages/driverApplicationForm/AddressInfo"));
const LicenseInfo = lazy(() => import("./pages/driverApplicationForm/licenseInfo"));
const Experience = lazy(() => import("./pages/driverApplicationForm/experience"));
const History = lazy(() => import("./pages/driverApplicationForm/history"));
const DriverApplications = lazy(() => import("./pages/driverApplications"));
const EditStudent = lazy(() => import("./pages/students/EditStudent"));
const ViewApplication = lazy(() => import("./pages/driverApplications/viewApplication"));
const RepresentativeProfile = lazy(() => import("./pages/representativeProfile"));


function App() {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route index path="/login" element={<Login />} exact />
          {/* <Route
            path="/driver-application"
            element={<DriverApplicationForm />}
            exact
          /> */}
          <Route path="*" element={<Notfound />} />

          <Route
            path="/driver-application"
            element={
              <DriverRegisterationLayout>
                <Outlet />
              </DriverRegisterationLayout>
            }
          >
            <Route path="personal" element={<ApplicantInfo />} />
            <Route path="address" element={<AddressInfo />} />
            <Route path="license" element={<LicenseInfo />} />
            <Route path="experience" element={<Experience />} />
            <Route path="history" element={<History />} />
            <Route path="education" element={<Education />} />
          </Route>
          <Route
            path="/"
            element={
              <PrivateLayout>
                <Outlet />
              </PrivateLayout>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />

            <Route path="workshop" element={<WorkShop />} exact />
            <Route path="add-used" element={<AddComponents />} exact />

            <Route path="users" element={<Users />} exact />
            <Route path="users/add" element={<AddUser />} exact />
            <Route path="students" element={<Students />} exact />
            <Route path="students/add" element={<AddStudent />} exact />
            <Route path="hhms" element={<Hhms />} exact />
            <Route path="hhms/add" element={<AddHhm />} exact />
            <Route path="hhms/edit/:id" element={<EditHhm />} exact />
            <Route path="hhms/:id" element={<HhmProfile />} exact />
            <Route path="time-tracking" element={<Attendence />} exact />
            <Route path="components" element={<ComponentsIndex />} exact />
            <Route path="drivers" element={<Drivers />} exact />
            <Route path="issues" element={<Issues />} exact />
            <Route path="issues/add" element={<AddIssue />} exact />
            <Route path="/issues/edit/:id" element={<EditIssue />} exact />
            <Route path="mechanics" element={<Mechanics />} exact />
            <Route path="modules" element={<Modules />} exact />
            <Route path="modules/add" element={<AddModule />} exact />
            <Route path="permissions" element={<Permissions />} exact />
            <Route path="permissions/add" element={<AddPermissions />} exact />
            <Route
              path="permissions-grid"
              element={<PermissionsGrid />}
              exact
            />
            <Route path="settings" element={<Settings />} exact />
            <Route path="buses" element={<BusIndex />} exact />
            <Route path="buses/add" element={<AddBus />} exact />
            <Route path="profile" element={<Profile />} exact />
            <Route path="tasks" element={<Task />} exact />
            <Route path="tasks-grid" element={<TaskGrid />} exact />
            <Route path="tasks/add" element={<AddTask />} exact />
            <Route
              path="components-types"
              element={<ComponentsTypes />}
              exact
            />
            <Route
              path="components-types/add"
              element={<AddComponentsTypes />}
              exact
            />
            <Route path="components/add" element={<AddComponent />} exact />
            <Route
              path="components/edit/:id"
              element={<EditComponent />}
              exact
            />
            <Route path="buses/:id" element={<BusProfile />} exact />
            <Route path="users/:id" element={<UserProfile />} exact />
            <Route path="students/:id" element={<StudentProfile />} exact />
            <Route path="buses/edit/:id" element={<EditBus />} exact />
            <Route path="users/edit/:id" element={<EditUser />} exact />
            <Route path="students/edit/:id" element={<EditStudent />} exact />
            <Route path="task/edit/:id" element={<EditTask />} exact />
            <Route path="permissions/edit" element={<EditPermission />} exact />
            <Route
              path="permissions/view"
              element={<ViewPermissions />}
              exact
            />
            <Route path="maintenance" element={<Maintenance />} exact />
            <Route
              path="maintenance/status/:id"
              element={<MaintenanceStatus />}
              exact
            />
            <Route path="maintenance/dvir/:id" element={<DVIR />} exact />
            <Route
              path="maintenance/profile/:id"
              element={<VehicleProfile />}
              exact
            />
            <Route
              path="tracking-vehicle"
              element={<TrackingVehicle />}
              exact
            />

            <Route path="schedule" element={<FleetSchedule />} exact />
            <Route
              path="driver-applications"
              element={<DriverApplications />}
              exact
            />
            <Route
              path="driver-applications/:id"
              element={<ViewApplication />}
              exact
            />

            {/* Representative Routes */}
            <Route
              path="representatives/:id"
              element={<RepresentativeProfile />}
              exact
            />
          </Route>

          <Route path="/test" element={<Test />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
